$primary: #4C4762;
$secondary: #E86A1B;
$primaryContainer : #787290;
$secondaryContainer: #FF9A4C;
$surface: #ffffff;
$background: #E1E2E1;
$error: #B71C1C;
$onPrimary: #ffffff;
$onSecondary: #ffffff;
$onSurface: #000000;
$onError: #ffffff;
$onBackground: #000000;

// other container colors
$headerColor: $primary;
$footerColor: transparent;
$linkColor: $secondaryContainer;
$linkColorHover: $secondary;