@import "./constants/colors.scss";
@import "./constants/fonts.scss";

html,
body,
#root {
    @extend .monsterrat-regular;
    height: 100vh;
    position: relative
}

#root>div {
    @extend .monsterrat-regular;
}

html,
body {
    background: $primary;
    background: linear-gradient(to right, $primaryContainer, white);
}

.body-container {
    flex: 1 1 auto;
    min-height: 0;
    overflow: auto;
}

a {
    color: $linkColor !important;
    display: inline-block;
    text-decoration: none;
    font-weight: 400;

    &:hover {
        color: $linkColorHover !important;
    }
}

h1,
h2,
h3,
h4,
h5 {
    text-shadow: 0px 10px 25px black;
    letter-spacing: 3px;
}


// form section
*:focus {
    outline: 0 !important;
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.0) !important;
}


input[type="text"]:focus,
select:focus,
input[type="password"]:focus {
    background-color: white;
    border-bottom: 2px solid $secondary !important;
    border-color: $primaryContainer;
}

input[type="text"],
input[type="password"],
input[type="email"] {
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
}

select {
    font-size: 16px;
    text-align: center;
    padding: 15px 32px !important;
}

input[type="text"]:placeholder {
    color: #cccccc;
}

input[type="button"],
input[type="submit"],
input[type="reset"],
button {
    background-color: $primaryContainer !important;
    border: none !important;
    color: white;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-box-shadow: 0 10px 30px 0 $primaryContainer !important;
    box-shadow: 0 10px 30px 0 $primaryContainer !important;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
    background-color: $primary !important;
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
    background-color: $primary !important;
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
}


.custom-orange-button {
    background-color: $primaryContainer !important;
    border: 1px solid $secondary !important;
    color: $secondaryContainer;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-box-shadow: 0 10px 30px 0 $primaryContainer !important;
    box-shadow: 0 10px 30px 0 $primaryContainer !important;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    margin: 5px 20px 40px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.custom-orange-button:hover {
    background-color: $primary !important;
}

.custom-orange-button:active {
    background-color: $primary !important;
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
}


.custom-orange-button-rounded {
    background-color: $primaryContainer !important;
    border: 1px solid $secondary !important;
    color: $secondaryContainer;
    padding: 15px 80px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-box-shadow: 0 10px 30px 0 $primaryContainer !important;
    box-shadow: 0 10px 30px 0 $primaryContainer !important;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    margin: 5px 20px 40px 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.custom-orange-button-rounded:hover {
    background-color: $primary !important;
}

.custom-orange-button-rounded:active {
    background-color: $primary !important;
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
}

.w-max-content {
    width: max-content !important;
}