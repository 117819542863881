@import "../constants/colors.scss";

.loaderContainer {
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    text-align: center;
    backdrop-filter: blur(3px);
    .loaderInner {
      position: fixed;
      top: 50%;
      left: 50%;
    }
    
    .loader {
      color: $primary;
    }
  }
  