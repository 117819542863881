@import "../constants/colors.scss";

.guest-container {
    .guest-header {
        flex: 0 1 auto;
    }

    .guest-scrollable-container {
        flex: 1 1 auto;
        min-height: 0;
        overflow: auto;

        .important {
            color: $error;
        }
    }
}