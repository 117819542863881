@import "../constants/colors.scss";

.footer {
    flex: 0 1 auto;
    width: 100%;
    height: 2.5rem;
    border-radius: 0px !important;
    background-color: $footerColor;

    .footer-content h4 {
        margin-top: auto;
        margin-bottom: auto;
    }
}